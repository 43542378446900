<template>
  <div class="aboutus-wrapper">
    <div class="nav">
      <div class="nav_title">
        <div class="section-img">
          <h3 class="title">{{ $t("L_ABOUT.BANNER_0001") }}</h3>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "aboutUS",
};
</script>

<style lang="scss">
@import "../../../assets/styles/pc/aboutus/index.scss";
</style>
